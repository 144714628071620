import Styles from "@/styles/Footer.module.css";
import Link from "next/link";

const FooterBottom = () => {
  return (
    <div
      className={`${Styles.footer__bottom} ${Styles.footer__bottom__dark} text-center`}
    >
      <ul
        className={`${Styles.footer__nav__menu} ${Styles.footer__nav__menu__1} text-center`}
      >
        <li>
          <Link href={"/info/privacy-policy"} title="গোপনীয়তা নীতি">
            গোপনীয়তা নীতি
          </Link>
        </li>
        {/* <li>
                                <Link href={"/legal"}>আইনসংক্রান্ত</Link>
                            </li>
                            <li>
                                <Link href={"/complaint"}>অভিযোগ</Link>
                            </li> */}
        {/* <li>
                                <Link href={"/investor-info"}>
                                    বিনিয়োগকারীর তথ্য
                                </Link>
                            </li> */}
        <li>
          <Link href={"/advertise-with-us"} title="আমাদের বিজ্ঞাপন দিন">
            আমাদের বিজ্ঞাপন দিন
          </Link>
        </li>
        {/* <li>
              <Link href={"/info/faq"} title="প্রায়শই জিজ্ঞাসিত প্রশ্ন (FAQ)">
                প্রায়শই জিজ্ঞাসিত প্রশ্ন (FAQ)
              </Link>
            </li> */}
        {/* <li>
                                <Link href={"/career"}>কর্মজীবন</Link>
                            </li> */}
        {/* <li>
                                <Link href={"/where-to-watch"}>
                                    কোথায় দেখতে হবে
                                </Link>
                            </li> */}
      </ul>
      <p className={`${Styles.copyright}`}>
        <span className={`${Styles.nowrap__text}`}>
          স্বত্ব ©{" "}
          {new Date().getFullYear().toString().getDigitBanglaFromEnglish()}{" "}
          টিবিএন২৪{" "}
        </span>
        <span className={`${Styles.nowrap__text}`}>
          | সম্পাদক: টিবিএন সম্পাদনা পরিষদ |{" "}
        </span>
        <span className={`${Styles.nowrap__text}`}>
          প্রকাশক: টোটাল ব্রডকাস্টিং নেটওয়ার্ক{" "}
        </span>
      </p>
    </div>
  );
};

export default FooterBottom;
